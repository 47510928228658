import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { RadioButton, PageLoader, InputField } from 'ui';
import { Controller, useForm } from 'react-hook-form';
import { paymentRegisterSchema } from 'validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '@chakra-ui/react';
import { getSetupIntent, getUserByUUID, useRegisterPayment } from 'services';
import { ICouponData, IPricingData, IProfileInputs } from 'models';
import { useQuery } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';

import { SubscriptionForm } from '../../molecules/SubscriptionForm';

type PaymentProps = {
  userProfile: IProfileInputs;
  setUserProfile: Dispatch<SetStateAction<IProfileInputs | undefined>>;
  pricingData?: IPricingData[];
  isPriceLoading?: boolean;
  authenticated?: boolean;
};

export const Payment: React.FC<PaymentProps> = ({
  userProfile,
  setUserProfile,
  pricingData,
  isPriceLoading,
  authenticated,
}) => {
  const { t, i18n } = useTranslation('common');
  const toast = useToast();
  const lang = i18n.language?.startsWith('en') ? 'en' : 'ja';

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<ICouponData>({
    resolver: yupResolver(paymentRegisterSchema),
    defaultValues: {
      coupon_code: '',
    },
    mode: 'onBlur',
  });

  const {
    mutateAsync: RegisterPaymentMutation,
    isLoading,
    data,
    isSuccess,
  } = useRegisterPayment(toast, t, 'add');

  const { data: subscribedData } = useQuery(
    ['get_user_detail', data?.data],
    () => getUserByUUID(userProfile.id!),
    {
      refetchOnWindowFocus: false,
      enabled: isSuccess,
      keepPreviousData: true,
      retry: false,
    },
  );

  useEffect(() => {
    if (subscribedData) {
      setUserProfile(subscribedData);
    }
  }, [subscribedData]);

  const { data: setupIntentData, isLoading: isSetupIntentLoading } = useQuery(
    'get-setup-intent',
    () => getSetupIntent(lang),
    {
      enabled: !!authenticated,
    },
  );

  if (isPriceLoading || isSetupIntentLoading) {
    return (
      <div className="h-screen">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="items-start">
      <h2 className="semibold20-28 text-gray-9 !text-[20px]">{t('Payment')}</h2>
      <div className="regular14-22 text-gray-8 mb-6 justify-between pt-2">
        <p>
          {t(
            "For 480 yen/month (excluding tax), you can use all of MELD's features, such as event liking, matching with companions, and requesting the addition of new events!",
          )}
        </p>
        <p className={'mt-4'}>
          {t(
            'For the first month, you can try it out for half price, 240 yen (excluding tax).',
          )}
        </p>
      </div>
      <form>
        <Controller
          name="price_id"
          control={control}
          render={({ field }) => (
            <RadioButton
              label="Select Plan:"
              field={field}
              name={'price_id'}
              items={
                pricingData?.map((data: IPricingData) => ({
                  value: data?.id,
                  title:
                    (i18n?.language.startsWith('en')
                      ? data?.label?.en?.time
                      : data?.label?.ja?.time) +
                    ` <span class="text-gray-7 semibold14-22">${
                      i18n?.language.startsWith('en')
                        ? data?.label?.en?.price
                        : data?.label?.ja?.price
                    }</span>`,
                })) || []
              }
            />
          )}
        />
        <div className="regular12-20 text-gray-9 pl-6">
          {pricingData?.map((data: IPricingData) => (
            <div
              key={data.id}
              dangerouslySetInnerHTML={{
                __html: i18n?.language.startsWith('en')
                  ? data?.label?.en?.label_description
                  : data?.label?.ja?.label_description,
              }}
            />
          ))}
        </div>
        {errors && (
          <p className="text-red-6 mt-1 text-sm">
            {t(errors?.price_id?.message as string)}
          </p>
        )}
        <div className="pt-4">
          <Controller
            name="coupon_code"
            control={control}
            render={({ field }) => (
              <InputField
                field={field}
                textTransform={'uppercase'}
                label="Coupon Code"
                subText="Discounts and coupons cannot be used at the same time for first time use."
              />
            )}
          />
        </div>

        {isSetupIntentLoading && <PageLoader />}
        {setupIntentData && (
          <Elements
            key={setupIntentData?.secret_key}
            stripe={setupIntentData?.stripe}
            options={{ clientSecret: setupIntentData?.secret_key }}
          >
            <SubscriptionForm
              RegisterPaymentMutation={RegisterPaymentMutation}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              watch={watch}
              setError={setError}
            />
          </Elements>
        )}
      </form>
    </div>
  );
};
