import * as React from 'react';
import Image from 'next/image';
import { SmallButton } from '../../atoms';
import { Flex } from '@chakra-ui/layout';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { createOrRetrieveChatRoom, getUserDetails } from 'services';
import { useChatList } from 'contexts';
import { IProfile } from 'models';
import { toastError } from 'toast-schema';
import { useToast } from '@chakra-ui/react';

interface propTypes {
  sender_id?: string;
  receiver_id?: string;
  userId?: string;
  subscription?: { is_subscribed: boolean };
}

export const ProfileActions: React.FC<propTypes> = ({
  sender_id,
  receiver_id,
  userId,
  subscription,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { setUserDetails } = useChatList();
  const toast = useToast();

  const handleMessageRedirection = async () => {
    if (sender_id && receiver_id) {
      setLoading(true);
      const roomID = await createOrRetrieveChatRoom(sender_id, receiver_id);
      const response = await getUserDetails(receiver_id);
      if (setUserDetails)
        setUserDetails((m: React.SetStateAction<Record<string, IProfile>>) => {
          return { ...m, ...response.data };
        });

      setLoading(false);
      router.push(`/chat/${roomID}`);
    }
  };

  const handleAccountDelete = () => {
    if (!userId) {
      toastError({
        toastId: 'account',
        toast,
        description: t('Error occurred'),
      });
      return;
    }

    if (subscription && subscription?.is_subscribed) {
      localStorage.setItem('delete_account', 'true');
      router.push('/account-delete-failed');
      return;
    } else {
      router.push('/profile/delete');
    }
  };

  React.useEffect(() => {
    if (typeof window !== undefined) {
      localStorage.removeItem('delete_account');
    }
  }, []);

  if (router.pathname.startsWith('/user')) {
    return (
      <Flex>
        {sender_id == receiver_id ? (
          <Link href="/profile/edit">
            <SmallButton
              as="div"
              textColor="gray-10"
              backgroundColor={'gray.1'}
              icon={
                <Image
                  src="/assets/svg/pencil.svg"
                  alt="edit"
                  height={15}
                  width={14}
                />
              }
              title={t('Edit Profile')}
            />
          </Link>
        ) : (
          <>
            <div className="mr-2">
              <SmallButton
                backgroundColor={'gray.1'}
                icon={
                  <Image
                    src="/assets/svg/message.svg"
                    alt="message"
                    height={15}
                    width={14}
                  />
                }
                isLoading={loading}
                title={t('Message')}
                onClick={handleMessageRedirection}
              />
            </div>
            <div>
              <SmallButton
                onClick={() => router.push(`/report?id=${receiver_id}`)}
                textColor="gray-10"
                backgroundColor={'gray.1'}
                icon={
                  <Image
                    src="/assets/svg/flag.svg"
                    alt="report"
                    height={15}
                    width={14}
                  />
                }
              />
            </div>
          </>
        )}
      </Flex>
    );
  }

  if (router.pathname != '/profile/edit') {
    return (
      <div className="flex flex-col items-end gap-3">
        <Link href="/profile/edit" legacyBehavior>
          <SmallButton
            textColor="gray-10"
            backgroundColor={'gray.1'}
            icon={
              <Image
                src="/assets/svg/edit.svg"
                alt="edit"
                height={15}
                width={14}
              />
            }
            title={t('Edit Profile')}
          />
        </Link>
        <span
          onClick={handleAccountDelete}
          className="text-red-5 semibold12-20 hover:text-red-6 cursor-pointer hover:font-semibold"
        >
          {t('Delete Account')}
        </span>
      </div>
    );
  }

  return null;
};
