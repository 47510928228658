import 'ui/styles/globals.css';
import NextNProgress from 'nextjs-progressbar';
import { ChakraProvider } from '@chakra-ui/react';
import { MetaTags, theme } from 'ui';
import { AuthContextProvider } from '../utils/auth';
import { appWithTranslation } from 'next-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppProps } from 'next/app';
import { source } from 'ui';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={client}>
      <AuthContextProvider>
        <ChakraProvider theme={theme}>
          <MetaTags />
          <NextNProgress color="#263238" options={{ showSpinner: false }} />
          <main className={`${source.variable} font-source`}>
            <Component {...pageProps} />
          </main>
        </ChakraProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp);
