import * as React from 'react';
import Image from 'next/image';
import { Icon } from '@iconify/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useChatList } from 'contexts';
import { User } from 'firebase/auth';
import { IProfileInputs } from 'models';

interface Props {
  src?: string;
  alt?: string;
  className?: string;
  userProfile: IProfileInputs;
  loading: boolean;
  user?: User | null;
}

export const BottomNavigationBar: React.FC<Props> = ({
  alt,
  userProfile,
  loading,
  user,
}) => {
  const router = useRouter();
  const { unreadCount } = useChatList();

  const Icons = [
    {
      icon: 'ant-design:calendar-filled',
      path: '/',
      label: 'Go to main page to view events',
    },
    {
      icon: 'mdi:message-reply',
      path: '/chat',
      label: 'Go to chat page',
    },
  ];

  return (
    <>
      {!loading && (
        <div className="bg-gray-2 xs:px-0 fixed bottom-0 h-[46px] w-full border-t border-[gray-5] px-6 pt-3">
          <div className="xs:px-8 mx-auto flex max-w-[600px] justify-between">
            {Icons.map((elem, ind) => (
              <Link href={elem.path} key={ind} aria-label={elem.label}>
                <div className="relative flex">
                  <Icon
                    icon={elem.icon}
                    className={`cursor-pointer  ${
                      elem.path === router.pathname
                        ? 'text-primary'
                        : 'text-gray-7'
                    }`}
                    height={28}
                    width={28}
                    key={ind}
                  />
                  {elem.path === '/chat' && unreadCount ? (
                    <span className="align-center absolute -top-1 left-5 flex justify-center rounded-xl bg-[#FF4D4F] px-[5px] text-[10px] text-white">
                      {unreadCount}
                    </span>
                  ) : null}
                </div>
              </Link>
            ))}
            {user && (
              <Link href={'/profile'} aria-label="Go to profile page">
                <Image
                  priority
                  src={
                    userProfile?.profile_picture || '/assets/image/Profile.png'
                  }
                  alt={alt ? alt : ''}
                  height={28}
                  width={28}
                  style={{ objectFit: 'cover' }}
                  className={` cursor-pointer rounded-full`}
                />
              </Link>
            )}
          </div>
          <div className="h-[50px]" />
        </div>
      )}
    </>
  );
};
