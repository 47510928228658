import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { IEventData } from 'models';

type TabMenuProps = {
  tabList: IEventData[];
  type?: string;
  sticky?: boolean;
  setSelectedTabIndex?: Dispatch<SetStateAction<number>>;
  selectedIndex?: number;
  persistTabSelection?: boolean;
  showFilters?: boolean;
  isAdmin?: boolean;
};

export const TabMenu: React.FC<TabMenuProps> = ({
  tabList,
  type,
  sticky,
  setSelectedTabIndex,
  selectedIndex,
  persistTabSelection,
  showFilters,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0 as number);

  const handleTabChange = (value: number) => {
    if (persistTabSelection) {
      setSelectedTabIndex && setSelectedTabIndex(value);
      localStorage.setItem('selectedTab', value.toString());
    } else {
      setIndex(value);
    }
  };

  return (
    <Tabs
      isLazy
      variant={'true'}
      className="pb-[60px]"
      index={persistTabSelection ? selectedIndex : index}
      onChange={handleTabChange}
    >
      <TabList
        className={`bg-gray-3 ${
          sticky ? 'sticky' : null
        } top-[46px] z-30 gap-6`}
        paddingLeft={isAdmin ? 3 : 0}
      >
        {tabList.map((tabItem, index) => (
          <Tab
            paddingLeft={0}
            paddingRight={0}
            paddingBottom={'4px'}
            marginTop={showFilters ? '160px' : 0}
            key={index}
            _selected={{
              borderColor: 'primary',
              color: 'gray.10',
              borderBottomWidth: '2px',
            }}
            borderColor="transparent"
            color="gray.7"
            borderBottomWidth="2px"
          >
            <span className="semibold16-24 sm:semibold20-28">
              {t(tabItem.label)}
            </span>
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabList.map((tabItem, index) => (
          <TabPanel
            key={index}
            marginTop={showFilters ? '65px' : '5px'}
            padding={0}
          >
            <>{tabItem.panel}</>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
