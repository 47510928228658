import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, AuthContextProps } from 'config';
import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import { toastError } from 'toast-schema';
import { useTranslation } from 'next-i18next';
import { useToast } from '@chakra-ui/react';

type UnwantedKey =
  | 'changePassword'
  | 'changeEmail'
  | 'resetPassword'
  | 'signUp'
  | 'setSelectedTabIndex'
  | 'setLoginStatus'
  | 'setLogoutStatus'
  | 'setRedirectedFromNavDrawer'
  | 'setAction'
  | 'setUserProfile';

const AuthContext = createContext<Omit<AuthContextProps, UnwantedKey>>({
  logIn: (email: string, password: string) => Promise.resolve(),
  logOut: () => Promise.resolve(),
  setUser: () => {},
  setAuthenticated: () => {},
  setLoading: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const toast = useToast();
  const { t } = useTranslation('common');

  const [user, setUser] = useState(null as User | null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAdmin] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdTokenResult(false);
        const isAdmin = token?.claims['admin'];
        if (!isAdmin) {
          toastError({
            toastId: 'login',
            toast,
            description: t('Only Admin User can log in.'),
          });
          logOut();
          setUser(null);
          setAuthenticated(false);
          setLoading(false);
          return;
        }
        setUser(user);
        setAuthenticated(true);
        setLoading(false);
      } else {
        setUser(null);
        setAuthenticated(false);
        setLoading(false);
      }
    });
  }, []);

  const logIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    await signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        logIn,
        logOut,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
