import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { hamburgerIcon, searchIcon } from '../../../assets';
import Image from 'next/image';
import { useRouter } from 'next/router';
interface TopNavProps {
  onOpen: () => void;
  setShowFilters: (value: boolean) => void;
}

export const TopNav: React.FC<TopNavProps> = ({
  onOpen,
  setShowFilters,
  ...rest
}) => {
  return (
    <div className={'xs:px-8 mx-auto max-w-[600px] px-4'}>
      <Flex
        ml={0}
        className="h-[46px]"
        alignItems="center"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={'space-between'}
        {...rest}
      >
        <Box color={'gray.7'} cursor={'pointer'} onClick={onOpen}>
          {hamburgerIcon}
        </Box>
        <Box display={'flex'}>
          <Image
            src="/assets/svg/logo-light.svg"
            height={55}
            width={55}
            alt="MELD"
          />
        </Box>

        <Box
          color={'gray.7'}
          cursor={'pointer'}
          onClick={() => setShowFilters(true)}
        >
          {searchIcon}
        </Box>
      </Flex>
    </div>
  );
};
