import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const RestrictedRoute = (AuthComponent: React.FC<{}>) => {
  function RestrictedComponent({ children }: React.PropsWithChildren<{}>) {
    const { authenticated, loading, user } = useAuth();

    useEffect(() => {
      if (authenticated == null) {
        return;
      }

      if (authenticated) {
        Router.push('/events');
      }
    }, [authenticated, loading, user]);

    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      );
    }
  };
};
