import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Button } from '../../atoms';

interface InfoType {
  text?: {
    item: string;
    id: number;
  }[];
  bullet?: {
    item: string;
    id: number;
  }[];
}
interface UsageContentType {
  id: string;
  title: string;
  info: InfoType;
  src: string;
}

export const AboutMeldPage = () => {
  const { t } = useTranslation('common');

  const router = useRouter();

  const usageContent: UsageContentType[] = [
    {
      id: '1',
      title: 'Find a live event',
      info: {
        text: [
          {
            id: 1,
            item: 'From the top screen, narrow down the dates of the event you want to go to.',
          },
        ],
      },
      src: '/assets/svg/find-event.svg',
    },
    {
      id: '2',
      title: 'Register to participate',
      info: {
        text: [
          {
            id: 1,
            item: `Tap the event you want to visit to see the event details screen.`,
          },
          {
            id: 2,
            item: `Press the Like button to select your status. (You must be a registered member to press the like button.)`,
          },
        ],
        bullet: [
          {
            id: 1,
            item: `For example, if you have two tickets and are looking for a companion, choose "Extra tickets"`,
          },
          {
            id: 2,
            item: 'If you want to be matched with someone who has already secured a ticket for you, select "Waiting for my ticket".',
          },
          {
            id: 3,
            item: `If you do not have a ticket and want to be matched with someone who does, select "I want to go with someone who is waiting for a ticket".`,
          },
          {
            id: 4,
            item: `If you are undecided about going but would like to go if you can find someone to accompany you, select "I don't have a ticket but would like to go"`,
          },
          {
            id: 5,
            item: `If you want to be matched with someone who is already at the event, select "I want to be matched with someone who is at the event".`,
          },
        ],
      },
      src: '/assets/svg/register-participate.svg',
    },
    {
      id: '3',
      title: 'Find a Companion',
      info: {
        text: [
          {
            id: 1,
            item: `If it matches your status, send a DM.`,
          },
          {
            id: 2,
            item: 'afterExchangingDMs',
          },

          { id: 3, item: 'Have fun and be safe on the day of the event!' },
        ],
      },
      src: '/assets/svg/find-companion.svg',
    },
  ];
  const aboutMeld = [
    { id: 1, text: 'About MELD 1' },
    { id: 2, text: 'About MELD 2' },
    { id: 3, text: 'About MELD 3' },
    { id: 4, text: 'About MELD 4' },
    { id: 5, text: 'About MELD 5' },
    { id: 6, text: 'About MELD 6' },
  ];

  const NavigateToPage = () => {
    router.push('/profile');
  };
  return (
    <>
      <h4 className="semibold20-28 text-gray-9">{t('About MELD')}</h4>

      {aboutMeld.map(elem => (
        <p
          className="regular14-22 text-gray-9 whitespace-pre-wrap pt-4"
          key={elem.id}
        >
          {t(elem.text)}
        </p>
      ))}

      <h4 className="semibold20-28 text-gray-9 pt-6 pb-4">
        {t('How to use MELD')}
      </h4>
      {usageContent.map(elem => (
        <div className="flex flex-col gap-y-4 pb-8" key={elem.id}>
          <div className="flex gap-x-2">
            <p className="bg-primary text-gray-1 semibold16-24 flex h-6 w-6 items-center justify-center rounded-xl">
              {elem.id}
            </p>
            <p className="regular16-24 text-gray-9" key={elem.id}>
              {t(elem.title)}
            </p>
          </div>
          {elem.info.text &&
            elem.info.text?.map(infoText => (
              <p className="regular14-22 text-gray-9" key={infoText.id}>
                {t(infoText.item)}
              </p>
            ))}
          {elem.info.bullet &&
            elem.info.bullet?.map(infoBullet => (
              <ul className="ml-[20px] -mt-3 list-disc" key={infoBullet.id}>
                <li className="regular14-22 text-gray-9">
                  {t(infoBullet.item)}
                </li>
              </ul>
            ))}
          {elem?.src && (
            <div className="flex items-center justify-center">
              <Image src={elem.src} height={222} width={222} alt="register" />
            </div>
          )}
        </div>
      ))}
      <p className="text-gray-9 pb-6 text-[14px]  leading-[28px]">
        {t('Explore event from the event list now!')}
      </p>
      <Button
        name={t('See Events')}
        buttonType="primary"
        onClick={() => router.push('/')}
      />
      <Button
        name={t('Go to My Page')}
        buttonType="secondary"
        onClick={NavigateToPage}
      />
    </>
  );
};
