import Image from 'next/image';
import { Icon } from '@iconify/react';
import { I18n, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { ScheduleTimes } from 'models';
import { addHours } from 'date-fns';
import { favoriteFillIcon, favoriteOutlineIcon } from '../../../assets';

type EventProps = {
  dateTime: string | number | Date;
  image: string;
  location: string;
  isNight?: boolean;
  title: string;
  id: string;
  times: ScheduleTimes;
  favorite_counts: number;
  isMyFavorite: boolean;
  isAdmin?: boolean;
};

const getMonth = (i18n: I18n, month: number) => {
  const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
  return i18n.language != 'ja'
    ? formatter.format(new Date(2003, month, 12))
    : (month + 1).toString();
};

interface YMDHM {
  year: number;
  month: string;
  day: number;
  hour: number;
  minute: string;
}

const useDate = (timestamp: Date, times: ScheduleTimes): YMDHM | undefined => {
  const { i18n } = useTranslation();
  const [formattedDate, setFormattedDate] = useState<YMDHM>();

  useEffect(() => {
    setFormattedDate({
      year: timestamp.getFullYear(),
      month: getMonth(i18n, timestamp.getMonth()),
      day: timestamp.getDate(),
      hour: times?.OpeningTime?.getHours() ?? 0,
      minute: String(times?.OpeningTime?.getMinutes()).padStart(2, '0'),
    });
  }, [timestamp, times, i18n]);

  return formattedDate;
};

export const Event: React.FC<EventProps> = ({
  dateTime,
  times,
  image,
  location,
  isNight,
  title,
  id,
  isAdmin,
  favorite_counts,
  isMyFavorite,
}) => {
  const { t, i18n } = useTranslation();
  const date = useMemo(() => new Date(dateTime), [dateTime]);

  const isLive =
    new Date() >= new Date(times?.StartTime!) &&
    new Date() <= addHours(new Date(times?.StartTime!), 12);

  const ymd = useDate(date, times);

  return (
    <div className={'cursor-pointer'}>
      <Link
        prefetch={false}
        href={isAdmin ? `/events/edit/${id}` : `/events/${id}`}
      >
        <span className="bg-gray-2 flex rounded-lg p-2 transition-all duration-300 hover:shadow-sm">
          {image ? (
            <div className="h-[82px] min-w-[80px]">
              <Image
                alt={title}
                height={82}
                width={80}
                priority
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                src={image}
                className={'h-20 rounded-md'}
              />
            </div>
          ) : (
            <div className="bg-gray-3 text-gray-8 flex h-[82px] items-center justify-between text-center text-[8px]">
              <p className="w-[80px] justify-center">
                {t('No Image Available')}
              </p>
            </div>
          )}

          <div className="ml-2 flex flex-auto flex-col justify-items-stretch">
            <div className={'flex flex-auto flex-row flex-wrap justify-end'}>
              {ymd ? (
                <p className="text-gray-7 regular12-20 flex-auto">
                  {t('date_format', {
                    year: ymd?.year,
                    month: ymd?.month,
                    day: ymd?.day,
                  })}
                  {'  '}
                  {ymd?.hour}:{ymd?.minute}
                </p>
              ) : (
                <p className="text-gray-7 regular12-20 invisible flex-auto">
                  .
                </p>
              )}
              {isLive ? (
                <Image
                  priority
                  src="/assets/svg/connected_tv.svg"
                  alt="live"
                  height={20}
                  width={20}
                />
              ) : isNight ? (
                <Icon
                  icon="clarity:moon-solid"
                  color="#8c8c8c"
                  width="20"
                  height="20"
                />
              ) : null}
            </div>
            <h4 className="xxs:max-w-[200px] xs:max-w-xs text-gray-10 semibold16-24 line-clamp-2 mt-2 flex max-w-[80px] overflow-hidden sm:max-w-[384px]">
              {title}
            </h4>
            <div className={'mt-2 flex justify-between'}>
              <div className="flex flex-row ">
                <div className="absolute h-[22px] w-[22px]">
                  <Image
                    priority
                    src="/assets/svg/pin-icon.svg"
                    alt={location}
                    height={22}
                    width={22}
                  />
                </div>
                <p className="regular14-22 text-gray-10 line-clamp-2 xxs:max-w-[200px] xs:max-w-xs ml-5 flex max-w-[80px] flex-wrap pl-1 sm:max-w-[384px]">
                  {t(location)}
                </p>
              </div>
              <div className="top-0 flex gap-[1px] align-middle">
                <div className="text-[20px]">
                  {isMyFavorite ? favoriteFillIcon : favoriteOutlineIcon}
                </div>
                {favorite_counts > 0 && (
                  <div className="b-2 regular14-22">
                    {favorite_counts <= 999 ? favorite_counts : '999+'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </span>
      </Link>
    </div>
  );
};
