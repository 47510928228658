import React, { useCallback, useEffect, useRef } from 'react';
import { Event } from '../../atoms';
import { getOpenAndCloseTime } from 'helpers';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from 'react-query';
import { IEvents } from 'models';
import { EventPaginatedResponse } from 'services/pagination';
import {
  Grid,
  GridItem,
  SkeletonCircle,
  SkeletonText,
  Spinner,
} from '@chakra-ui/react';

interface PanelProps {
  data?: InfiniteData<EventPaginatedResponse<IEvents[]>>;
  hasNext?: boolean;
  fetchNext: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<EventPaginatedResponse<IEvents[]>, unknown>
  >;
  isFetchingNext?: boolean;
  isLoading: boolean;
  isAdmin?: boolean;
}

export const Panel: React.FC<PanelProps> = props => {
  const observerElem = useRef<HTMLDivElement>(null);

  const { data, hasNext, fetchNext, isFetchingNext, isLoading, isAdmin } =
    props;

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;
      if (target.isIntersecting && hasNext) {
        fetchNext();
      }
    },
    [fetchNext, hasNext],
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    if (element) {
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [fetchNext, hasNext, handleObserver]);

  const Loader = () => (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={6} marginTop="1rem">
        <GridItem colSpan={1}>
          <SkeletonCircle></SkeletonCircle>
        </GridItem>
        <GridItem colSpan={4}>
          <SkeletonText></SkeletonText>
        </GridItem>
      </Grid>
    </>
  );
  return (
    <>
      {isLoading && <Loader />}
      {data?.pages?.map(page =>
        page?.data?.map(
          (
            {
              id,
              date,
              name,
              image,
              venue,
              show_time,
              event_type,
              has_favorite,
              favorite_counts,
            },
            index,
          ) => (
            <div className={`mb-2 ${index == 0 ? 'mt-[22px]' : ''}`} key={id}>
              <Event
                isAdmin={isAdmin}
                times={getOpenAndCloseTime({ show_time })}
                key={id}
                id={id}
                dateTime={date}
                title={name}
                image={image || ''}
                location={venue?.[0] ?? ''}
                isNight={event_type === 'night'}
                isMyFavorite={has_favorite}
                favorite_counts={favorite_counts}
              />
            </div>
          ),
        ),
      )}

      <div className="loader" ref={observerElem}>
        {isFetchingNext && hasNext ? (
          <div className="flex items-center justify-center pt-3">
            <Spinner />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
